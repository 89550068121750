import { RoleServiceFactoryService } from 'src/app/shared/services/role-service-factory.service';
import { ProfileService } from './../../shared/services/profile.service';
import { RemindersService } from './../../shared/services/reminders.service';
import { OAuthService } from "angular-oauth2-oidc";
import { Component, OnInit, Injector, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
declare var $: any;

import { MenuService } from "../../core/menu/menu.service";
import { SettingsService } from "../../core/settings/settings.service";
import { AppConfigService } from 'src/app/services/app-config.service';
import * as _ from 'lodash';
import { AdministratorService } from "src/app/shared/services/administrator.service";
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { LoggingService } from 'src/app/shared/services/logging.service';


@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {


  navIcons = [
    {
      link: 'home',
      icon: 'home-alt'
    },
    {
      link: 'contacts',
      icon: 'address-book'
    },
    {
      link: 'my timeline',
      icon: 'list-ul'
    },
    {
      link: 'students',
      icon: 'user'
    },
    {
      link: 'calendar',
      icon: 'calendar'
    },
    {
      link: 'inbox',
      icon: 'envelope'
    },
    {
      link: 'news & events',
      icon: 'megaphone'
    },
    {
      link: 'resource center',
      icon: 'books'
    },
    {
      link: 'preferences',
      icon: 'cog'
    },
    {
      link: 'faq',
      icon: 'question-circle'
    },
    {
      link: 'help desk',
      icon: 'life-ring'
    },
    {
      link: 'help',
      icon: 'life-ring'
    },
    {
      link: 'district support',
      icon: 'life-ring'
    },
    {
      link: 'classic mode',
      icon: 'external-link'
    },
    {
      link: 'logout',
      icon: 'sign-out-alt'
    },
    {
      link: 'classes',
      icon: 'layer-group'
    },
    {
      link: 'expectations',
      icon: 'lightbulb'
    },
    {
      link: 'files',
      icon: 'folder'
    },
    {
      link: 'certificates',
      icon: 'award'
    },
    {
      link: 'surveys',
      icon: 'pen-square'
    },
    {
      link: 'communications',
      icon: 'comments'
    },

    {
      link: 'resources',
      icon: 'books'
    },
    {
      link: 'checklists',
      icon: 'list-ul'
    },
    {
      link: 'search',
      icon: 'search'
    },
    {
      link: 'qr codes',
      icon: 'qrcode'
    },

  ]
  isAdminAccess: boolean = false;
  menuItems: Array<any>;
  router: Router;
  sbclickEvent = "click.sidebar-toggle";
  $doc: any = null;
  role;
  baseUrl: string = this.appConfig.configuration.environment.endPoints.customerServices;
  menuItemsIcons = []
  constructor(
    public menu: MenuService,
    public settings: SettingsService,
    public injector: Injector,
    private oauthService: OAuthService,
    private reminderService: RemindersService,
    private profileService: ProfileService,
    private appConfig: AppConfigService,
    private rs: RoleServiceFactoryService,
    private adminService: AdministratorService,
    private cdRef: ChangeDetectorRef,
    private loggingService: LoggingService,
  ) {
    this.isAdminhasAccess();
    this.menuItems = menu.getMenu()
  }

  ngOnInit() {

    this.router = this.injector.get(Router);
    this.role = this.profileService.profile.role;

    this.router.events.subscribe((val) => {
      // close any submenu opened when route changes
      this.removeFloatingNav();
      // scroll view to top
      window.scrollTo(0, 0);
      // close sidebar on route change
      this.settings.setLayoutSetting("asideToggled", false);
    });

    // enable sidebar autoclose from extenal clicks
    this.anyClickClose();
    //this.filterMenus();
    this.MapMenuIcons();

  }
  MapMenuIcons() {
    this.menuItemsIcons = this.menuItems.map(menuItem => {
      let icon = this.navIcons.find(ico => ico.link == menuItem.text.toLowerCase());
      if (icon) {
        menuItem.linkIcon = icon.icon;
      } else {
        menuItem.linkIcon = 'puzzle-piece'
      }
      return menuItem;
    })
    let t = this.menuItemsIcons;
  }
  //Comments -- Disabled these lines to show communications in PROD env
  // filterMenus(){
  //   if(this.appConfig.configuration.environment.configuration.environment_name == 'Production'){

  //     this.menuItems = this.menuItems.filter(a=> !('CommunicationsInbox'.indexOf(a.text) >=0 ))

  //   }
  // }

  isAdminhasAccess() {
    const profile = this.profileService.profile;
    if (this.profileService.identity.userType == 'Administrator') {

      return this.adminService.allowAdminAccount(profile.firstName, profile.lastName, profile.email).subscribe(result => {
        if (result != null) {
          this.isAdminAccess = true;
        }
        this.filterAdminMenu()
        this.MapMenuIcons()
      })
    }
  }

  filterAdminMenu() {
    if (!this.isAdminAccess) {
      this.menuItems = this.menuItems.filter(item => item.text !== 'Communications')
      this.cdRef.markForCheck();
    }
  }
  logout() {
    let student = {
      studentId: this.profileService.profile.id
    }
    //this.loggingService.insertLogging(student, "Logout");

    this.oauthService.loadDiscoveryDocument().then((response) => {
      if (this.profileService.role == this.rs.TeacherRole) {
        this.reminderService.stopReminders(this.profileService.identity.userIdRole).toPromise().then(() => {

          this.oauthService.logOut();
          localStorage.clear();
        })
      } else {
        this.oauthService.logOut();
        localStorage.clear();
      }
    });
  }

  getIcon(navlink: any) {
    let link = navlink.toLowerCase();
    let iconFav = this.navIcons.find(n => n.link == link);
    return iconFav;
  }

  anyClickClose() {
    this.$doc = $(document).on(this.sbclickEvent, (e) => {
      if (!$(e.target).parents(".aside-container").length) {
        this.settings.setLayoutSetting("asideToggled", false);
      }
    });
  }
  goParentPortal() {
    //this.loggingService.insertLogging(null, `Classic Mode`);
    switch (this.baseUrl) {

      //DEV & QA
      case 'https://servicesdev.lausd.net/customers':
        window.open(
          "https://tst-parentws.lausd.net/parentauthen/webapi/authenticate/authorize?client_id=1a3b20207b9d480eaa5e7c0ee16a5558&app=passport&redirect_uri=https://devparentprofile.lausd.net/parentaccesstest/authenticate.do&lang=en",
          "_blank"
        );
        break;

      //STG
      case 'https://servicesstg.lausd.net/customers':
        window.open(
          "https://parentws.lausd.net/parentauthen/webapi/authenticate/authorize?client_id=172cfe9df90043a1aed8138baf315a4f&app=passport&redirect_uri=https://parentportalapp.lausd.net/parentaccess/authenticate.do&lang=en",
          "_blank"
        );
        break;

      //PROD
      default:
        window.open(
          "https://parentws.lausd.net/parentauthen/webapi/authenticate/authorize?client_id=172cfe9df90043a1aed8138baf315a4f&app=passport&redirect_uri=https://parentportalapp.lausd.net/parentaccess/authenticate.do&lang=en",
          "_blank"
        );
        break;
    }

  }

  goHelpDesk() {
    this.loggingService.insertLogging(null, "Customer Service");

    if (this.appConfig.configuration.environment.endPoints.authenticationServices.includes('servicesdev')) {
      window.open('https://lausd.service-now.com/navpage.do', '_blank');
    } else if (this.appConfig.configuration.environment.endPoints.authenticationServices.includes('servicesstg')) {
      window.open('https://lausd.service-now.com/navpage.do', '_blank');
    } else {
      window.open('https://lausd.service-now.com/navpage.do', '_blank');
    }
  }


  ngOnDestroy() {
    if (this.$doc) this.$doc.off(this.sbclickEvent);
  }

  toggleSubmenuClick(event) {
    event.preventDefault();

    let menuClicked = event.target.innerText;

    switch (menuClicked) {
      case "Home":
        menuClicked = "Home Page";
        break;
      case "Students":
        menuClicked = "All Students";
        break;
      case "Resources":
        menuClicked = "Resource Home";
        break;
      case "News & Events":
        menuClicked = "News Events";
        break;
      case "Resource":
        menuClicked = "Resource Home";
        break;
      default:
        break;
    }

    this.loggingService.insertLogging(null, menuClicked);

    if (
      !this.isSidebarCollapsed() &&
      !this.isSidebarCollapsedText() &&
      !this.isEnabledHover()
    ) {
      let ul = $(event.currentTarget.nextElementSibling);

      // hide other submenus
      let parentNav = ul.parents(".sidebar-subnav");
      $(".sidebar-subnav").each((idx, el) => {
        let $el = $(el);
        // if element is not a parent or self ul
        if (el !== parentNav[0] && el !== ul[0]) {
          this.closeMenu($el);
        }
      });

      // abort if not UL to process
      if (!ul.length) {
        return;
      }

      // any child menu should start closed
      ul.find(".sidebar-subnav").each((idx, el) => {
        this.closeMenu($(el));
      });

      // toggle UL height
      const ulHeight = ul.css("height");
      if (ulHeight === "auto" || parseInt(ulHeight, 10)) {
        this.closeMenu(ul);
      } else {
        // expand menu
        ul.on("transitionend", () => {
          ul.css("height", "auto").off("transitionend");
        }).css("height", ul[0].scrollHeight);
        // add class to manage animation
        ul.addClass("opening");
      }
    }
  }

  // Close menu collapsing height
  closeMenu(elem) {
    elem.css("height", elem[0].scrollHeight); // set height
    elem.css("height", 0); // and move to zero to collapse
    elem.removeClass("opening");
  }

  toggleSubmenuHover(event) {
    let self = this;
    if (
      this.isSidebarCollapsed() ||
      this.isSidebarCollapsedText() ||
      this.isEnabledHover()
    ) {
      event.preventDefault();

      this.removeFloatingNav();

      let ul = $(event.currentTarget.nextElementSibling);
      let anchor = $(event.currentTarget);

      if (!ul.length) {
        return; // if not submenu return
      }

      let $aside = $(".aside-container");
      let $asideInner = $aside.children(".aside-inner"); // for top offset calculation
      let $sidebar = $asideInner.children(".sidebar");
      let mar =
        parseInt($asideInner.css("padding-top"), 0) +
        parseInt($aside.css("padding-top"), 0);
      let itemTop = anchor.parent().position().top + mar - $sidebar.scrollTop();

      let floatingNav = ul.clone().appendTo($aside);
      let vwHeight = document.body.clientHeight;

      // let itemTop = anchor.position().top || anchor.offset().top;

      floatingNav.addClass("nav-floating");

      // each item has ~40px height
      // multiply to force space for at least N items
      var safeOffsetValue = 40 * 5;
      var navHeight = floatingNav.outerHeight(true) + 2; // 2px border
      var safeOffset =
        navHeight < safeOffsetValue ? navHeight : safeOffsetValue;

      var displacement = 25; // displacement in px from bottom

      // if not enough space to show N items, use then calculated 'safeOffset'
      var menuTop =
        vwHeight - itemTop > safeOffset
          ? itemTop
          : vwHeight - safeOffset - displacement;

      floatingNav
        .removeClass("opening") // necesary for demo if switched between normal//collapsed mode
        .css({
          position: this.settings.getLayoutSetting("isFixed")
            ? "fixed"
            : "absolute",
          top: menuTop,
          bottom:
            floatingNav.outerHeight(true) + menuTop > vwHeight
              ? displacement + "px"
              : "auto",
        });

      floatingNav
        .on("mouseleave", () => {
          floatingNav.remove();
        })
        .find("a")
        .on("click", function (e) {
          e.preventDefault(); // prevents page reload on click
          // get the exact route path to navigate
          let routeTo = $(this).attr("route");
          if (routeTo) self.router.navigate([routeTo]);
        });

      this.listenForExternalClicks();
    }
  }

  listenForExternalClicks() {
    let $doc = $(document).on("click.sidebar", (e) => {
      if (!$(e.target).parents(".aside-container").length) {
        this.removeFloatingNav();
        $doc.off("click.sidebar");
      }
    });
  }

  removeFloatingNav() {
    $(".nav-floating").remove();
  }

  isSidebarCollapsed() {
    return this.settings.getLayoutSetting("isCollapsed");
  }
  isSidebarCollapsedText() {
    return this.settings.getLayoutSetting("isCollapsedText");
  }
  isEnabledHover() {
    return this.settings.getLayoutSetting("asideHover");
  }
}
