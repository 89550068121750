import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { timer } from 'rxjs';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { fabAnimations } from './fab-button.animations';
import { environment } from 'src/environments/environment';
import { LoggingService } from "src/app/shared/services/logging.service";

export interface IFabIcons {
  iconName: string;
  message: string;
  action: boolean;
}

@Component({
  selector: 'app-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss'],
  animations: fabAnimations,
})
export class FabButtonComponent implements OnInit, OnChanges {
  fabButtons = ['thumb_up_off_alt', 'forum']
  buttons = [];
  fabTogglerState = 'inactive';
  @Output() actionSelected = new EventEmitter();
  showChat = false;

  userRole = this.profileService.profile.role;

  get isProd() {
    if(environment.environment_name == 'Production') return true
    else return false
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private profileService: ProfileService,
    private loggingService: LoggingService
     ) {}

  ngOnInit() {
    this.checkRequiredFields(this.fabButtons);
    this.getCounselorButtons();

    //remove from parent on prod
    if(this.isProd && this.userRole == 'Parent'){
      this.fabButtons = ['thumb_up_off_alt']
    }
  }

  getCounselorButtons() {
    if(this.profileService.role == 'Counselor') {
      this.fabButtons = ['thumb_up_off_alt']
    }
  }

  ngOnChanges() {
    this.checkRequiredFields(this.fabButtons);
  }

  checkRequiredFields(input: any) {
    if (input === null) {
      throw new Error('Missing  fabButtons');
    }
  }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
    this.cdRef.detectChanges();
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
    this.cdRef.detectChanges();
  }

  onToggleFab() {
    //this.loggingService.insertLogging(null, 'Fab Button - toggle');
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  action(value: string) {
    this.actionSelected.emit(value);
    this.onToggleFab();
  }


  ngOnDestroy() {
    this.cdRef.detach();
  }

}
