import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ApiService } from "./api.service";
import { Location } from '@angular/common';
import { ProfileService } from "../services/profile.service";
import { StudentsService } from "src/app/shared/services/students.service";
import { ParentsService } from './parents.service';
import { HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  baseUrl: string = this.appConfig.configuration.environment.endPoints.customerServices;
  currentDateTime: Date;

  sessionId: string;
  userId: string;
  userType: string;
  requestedAction: string;
  requestOrigin: string;
  requestedDateTime: string;
  userBrowserOS: string;
  userLanguages: string;
  studentId: string;
  studentGrade: string;
  costCenterCode: string;
  appType: string;

  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private studentService: StudentsService,
    private parentServices: ParentsService,
    public profileService: ProfileService,
    private location: Location,
  ) { }

  insertLogging(student: any, action: string): void {
    if (typeof student === 'string') {
      this.studentService.getStudentById(student).subscribe({
        next: (studentDetails) => {
          this.processLogging(studentDetails, action);
        },
        error: (err) => {
          console.error('Error fetching student details', err);
          this.processLogging(null, action);
        }
      });
    } else {
      this.processLogging(student, action);
    }
  }

  processLogging(student: any, action: string): void {
    this.currentDateTime = new Date();

    this.userType = this.profileService.profile.role;
    this.userId = this.profileService.profile.id;
    this.userBrowserOS = window.navigator.userAgent;
    this.userLanguages = this.getUserLanguage();
    this.requestOrigin = this.getCurrentPath();
    this.sessionId = this.generateSessionId();

    let formData = new FormData();
    formData.append('sessionId', this.sessionId);
    formData.append('userId', this.userId);
    formData.append('userType', this.userType);
    formData.append('requestedAction', action);
    formData.append('requestOrigin', this.requestOrigin);
    formData.append('requestedDateTime', this.currentDateTime.toISOString());
    formData.append('userBrowserOS', this.userBrowserOS);
    formData.append('userLanguages', this.userLanguages);
    formData.append('appType', 'WEB');

    if (student !== null && student !== undefined) {
      formData.append('studentId', student.studentId || '');
      formData.append('studentGrade', student.gradeCode || student.gradeLevel || '');
      formData.append('costCenterCode', student.costCenterCode || '');
    }

    const path = `${this.baseUrl}/v1/pass-usage-logs/insert-usage-log`;

    let headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem("access_token")}`
    });

    this.api.postLogging(path, formData, headers)
      .pipe(catchError((err) => {
        console.error("Logging request failed:", err);
        return of(null);
      }))
      .subscribe();
  }

  generateSessionId(): string {
    let sessionId = sessionStorage.getItem('SESSION_ID');
    if (!sessionId) {
      sessionId = this.createSessionId();
      sessionStorage.setItem('SESSION_ID', sessionId);
    }
    return sessionId;
  }

  // Create a unique session ID by getting a high-res timestamp, random number, and adding them up in base 36
  createSessionId(): string {
    const timestamp = performance.now().toString(36);
    const random = Math.random().toString(36).substring(2, 15);
    return timestamp + random;
  }

  getUserLanguage(): string {
    return navigator.language || navigator.languages[0] || 'en-US';
  }

  getCurrentPath(): string {
    return this.location.path();
  }
}
