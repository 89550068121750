import { Component, OnInit } from '@angular/core';
import { LoggingService } from 'src/app/shared/services/logging.service';
import { StudentsService } from "src/app/shared/services/students.service";
import { ActivatedRoute } from "@angular/router";
import { IStudentResume } from '../../student-list-widget/models/IStudentResume';


@Component({
  selector: 'app-student-health-and-wellness',
  templateUrl: './student-health-and-wellness.component.html',
  styleUrls: ['./student-health-and-wellness.component.scss']
})
export class StudentHealthAndWellnessComponent implements OnInit {

  studentId: string = null;
  student: IStudentResume = null;
  constructor(
    private loggingService: LoggingService,
    private studentService: StudentsService,
    private activeRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.activeRoute.parent.params.subscribe((data) => {
      this.studentId = data.studentId;
    });
    this.student = this.studentService.currentStudent();
    this.studentId = this.student.studentId;

  }

  logNavigation(action: string) {
    this.loggingService.insertLogging(this.studentId, `Health and Wellness: ${action}`);
  }
}
