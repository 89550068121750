import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable, of, throwError } from "rxjs";
import { catchError, finalize, share, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  baseUrl: string = "";
  get parentId(): string {
    return this.identity().userIdRole;
  }
  get parentUsername(): string {
    return this.identity().sub;
  }
  parentsApi: string = "";

  constructor(
    private httpClient: HttpClient,
    private oAuthService: OAuthService
  ) {}
  identity() {
    return this.oAuthService.getIdentityClaims() as any;
  }
  get(url: string): Observable<object> {
    let request = url;
    return this.httpClient.get(request).pipe(catchError(err=>of(err)));
  }

  getReport(url: string): Observable<object> {
    let request = url;
    let headers = new HttpHeaders({
      "Authorization" : this.oAuthService.authorizationHeader()
    });
    return this.httpClient.get(request, { headers: headers }).pipe(catchError(err=>of(err)));
  }  

  getNoCache(url: string): Observable<object> {
    let headers = new HttpHeaders({
      "Cache-Control":
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
      Pragma: "no-cache",
      Expires: "0",
    });
    let request = url;
    return this.httpClient.get(request, { headers: headers }).pipe(catchError(err=>of(err)));;
  }
  getSilent(url: string): Observable<object> {
    let request = url;
    return this.httpClient.get(request, { params: { spinner: "no" } }).pipe(catchError(err=>of(err)));;
  }

  cachedObservable: Array<Observable<any>> = [];
  cacheRequests = [];
  cached(url: string, params): Observable<any> {
    let observable: Observable<any>;
    if (this.cacheRequests[url]) {
      observable = of(this.cacheRequests[url]);
    } else if (this.cachedObservable[url]) {
      observable = this.cachedObservable[url];
    } else {
      this.cachedObservable[url] = this.httpClient.get<any>(url).pipe(
        tap((res) => (this.cacheRequests[url] = res)),
        share(),
        finalize(() => (this.cachedObservable[url] = null))
      );
      observable = this.cachedObservable[url];
    }
    return observable;
  }

  getCache(url: string, params): Observable<object> {
    let headers = new HttpHeaders({
      "Cache-Control":
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
      Pragma: "no-cache",
      Expires: "0",
    });
    let request = url;
    let cache = this.cacheRequests[request];
    if (cache) return of(cache.response);
    return this.httpClient
      .get(request, { headers: headers, params: params })
      .pipe(catchError(err=>of(err)),tap((data) => (this.cacheRequests[request] = data)));

  }
  getWithParams(url: string, params): Observable<object> {
    let request = url;
    return this.httpClient.get(request, { params: params }).pipe(catchError(err=>of(err)));
  }
  post(url: string, body: any): Observable<object> {
    let request = url;
    return this.httpClient.post(request, body).pipe(catchError(err=>of(err)));;
  }
  patch(url: string, body: any): Observable<object> {
    let request = url;
    return this.httpClient.patch(request, body).pipe(catchError(err=>of(err)));;
  }
  put(url: string, body: any): Observable<object> {
    let request = url;
    return this.httpClient.put(request, body).pipe(catchError(err=>of(err)));;
  }
  delete(url: string): Observable<object> {
    let request = url;
    return this.httpClient.delete(request).pipe(catchError(err=>of(err)));;
  }
  postLogging(url: string, body: any, headers: HttpHeaders): Observable<object> {
    let request = url;
    return this.httpClient.post(request, body, { headers: headers }).pipe(catchError(err => of(err)));;
  }
  postForms(url: string, body: any): Observable<object> {
    let request = url;
    var form_data = new FormData();

    for (var key in body) {
      form_data.append(key, body[key]);
    }

    return this.httpClient.post(request, form_data).pipe(catchError(err=>of(err)));;
  }
  postFile(url: string, body: any, file: File): Observable<object> {
    let request = url;
    let formData = new FormData();
    formData.append("upload", file);
    for (var key in body) {
      formData.append(key, body[key]);
    }
    return this.httpClient.post(request, formData).pipe(catchError(err=>of(err)));;
  }

  weather(location): Observable<object> {
    return this.httpClient.get(
      "http://api.weatherapi.com/v1/forecast.json?key=60ade2ab4cd94a7e8bd134747210403&q=" +
        location +
        "&days=1&aqi=no&alerts=no"
    );
  }

  getlocation() {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            let latlng =
              position.coords.latitude + "," + position.coords.longitude;
            resolve(latlng);
          },
          (err) => {
            if (err) {
              let latlng = 34.052235 + "," + -118.243683;
              resolve(latlng);
            }
          }
        );
      }
    });
  }
}
