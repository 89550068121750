import { switchMap, tap } from "rxjs/operators";
import { mergeMap } from "rxjs/operators";
import { ParentsService } from "./../../../../shared/services/parents.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { IFaqGroup } from "src/app/shared/components/faq-section-widget/models/Faq";
import { HelpCenterService } from "src/app/shared/services/help-center.service";
import { IStudentEmergencyContact } from "src/app/shared/services/student.service.models";
import { StudentsService } from "src/app/shared/services/students.service";
import * as m from "moment";
import { execFile } from "child_process";

import { TourService } from "ngx-ui-tour-md-menu";
import { MdMenuPlacement } from "ngx-ui-tour-md-menu";
import { MatDialog } from "@angular/material/dialog";
import { TourModalComponent } from "../../tour-modal/tour-modal.component";
import * as _ from "lodash";
import swal from "sweetalert";
import { NotificationServices } from "src/app/shared/services/notification.service";
import { ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import { ProfileService } from "src/app/shared/services/profile.service";
import { LoggingService } from "src/app/shared/services/logging.service";
import { merge } from "lodash";

@Component({
  selector: "app-student-emergency",
  templateUrl: "./student-emergency.component.html",
  styleUrls: ["./student-emergency.component.scss"],
})
export class StudentEmergencyComponent implements OnInit {
  emergencyContacts: IStudentEmergencyContact = null;
  emContacts: IStudentEmergencyContact = null;
  lastUpdated: any;
  parentChange = false;
  editorName;

  contactForm0 = this.fb.group({
    cNum: [""],
    phone: [""],
    workPhone: [""],
    cEmergency: [""],
    cAttendance: [""],
    cGenInfo: [""],
    cAuthText: [""],
    pNum: [""],
    pEmergency: [""],
    pAttendance: [""],
    pGenInfo: [""],
    pAuthText: [""],
    wNum: [""],
    wEmergency: [""],
    wAttendance: [""],
    wGenInfo: [""],
    wAuthText: [""],
  });
  contactForm1 = this.fb.group({
    cNum: [""],
    phone: [""],
    workPhone: [""],
    cEmergency: [""],
    cAttendance: [""],
    cGenInfo: [""],
    cAuthText: [""],
    pNum: [""],
    pEmergency: [""],
    pAttendance: [""],
    pGenInfo: [""],
    pAuthText: [""],
    wNum: [""],
    wEmergency: [""],
    wAttendance: [""],
    wGenInfo: [""],
    wAuthText: [""],
  });
  emContactsList = [];

  isReadOnly0: boolean = true;
  isReadOnly1: boolean = true;
  isEditing0: boolean = false;
  isEditing1: boolean = false;
  isDisabled0: boolean = true;
  isDisabled1: boolean = true;
  isEditingEm: any = false;
  isParent: boolean = false;

  studentId: any;
  misisId: string;
  parentId: string;

  role = this.profileService.profile.role;
  isReadOnly: boolean[] = [];
  isEditing: boolean[] = [];
  isDisabled: boolean[] = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private parentService: ParentsService,
    private fb: FormBuilder,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private toastr: ToastrService,
    private loggingService: LoggingService,
  ) {}

  ngOnInit(): void {
    this.studentId = this.studentService.currentStudent().studentId;

    let misisRequest: Observable<any> = null;
    if (this.profileService.profile.role == "Parent") {
      this.parentId = this.profileService.profile.id;
      this.isParent = true;
      misisRequest = this.studentService
        .getStudentEmergencyContacts(this.studentId, this.parentId)
        .pipe(
          mergeMap((contacts) =>{
            this.misisId = (contacts as any).parentId;
            this.initFormValues(contacts.parents.length)
            return of(contacts);
          })
        );
    } else {
      this.parentId = null;
      misisRequest = this.studentService
        .getParentsByStudentId(this.studentId)
        .pipe(
          mergeMap((parents) => {

            this.initFormValues(parents.length)


            var firstParentId =
              this.parentId || parents.find((a) => a.misisId)?.id;
            this.misisId = parents.find((a) => a.misisId)?.misisId;
            return this.studentService.getStudentEmergencyContacts(
              this.studentId,
              firstParentId
            );
          })
        );
    }

    misisRequest
      .pipe(
        tap((contacts) => {
          this.emergencyContacts = contacts;
          console.log(this.emergencyContacts);
          this.emContacts = this.emergencyContacts;
          this.checkNullData(this.emergencyContacts);
          this.lastUpdated = m();
        })
      )
      .subscribe(() => {
        this.startTour();
      });
  }
  initFormValues(size){
    for(let i = 0; i < size; i++){
      this.isReadOnly.push(true)
      this.isEditing.push(false)
      this.isDisabled.push(true);
    }
    // this.isReadOnly = new Array(size);
    // this.isEditing = new Array(size);
    // this.isDisabled = new Array(size);
    // this.isReadOnly = this.isReadOnly.map(i=>true)
    // this.isEditing = this.isEditing.map(i=>false)
    // this.isDisabled = this.isDisabled.map(i=>true)
  }
  startTour() {
    let above: MdMenuPlacement = { yPosition: "above" };
    let below: MdMenuPlacement = { yPosition: "below" };
    let left: MdMenuPlacement = { xPosition: "before" };
    let right: MdMenuPlacement = { xPosition: "after" };

    this.tourService.initialize([
      {
        anchorId: "start-tour",
        content:
          "All emergency contacts associated with the student will appear here",
        title: "Emergency Contacts",
      },
      {
        anchorId: "guardians",
        content: "Clicking on each Guardian/Parent will expand more details",
        title: "Guardians",
      },
      {
        anchorId: "contacts",
        content:
          "All other contacts registered to the student will appear here",
        title: "Additional Contacts",
      },
    ]);
    if (this.profileService.profile.hasTour) {
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: "warning",
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: "Yes, turn off tour",
          value: true,
          visible: true,
          closeModal: true,
        },
      },
    }).then((res) => {
      if (res) {
        // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  getMisisId() {
    this.parentService.getStudentsByParent().subscribe((students) => {
      let studentObj;
      _.each(students, (student) => {
        if (student.studentId == this.studentId) {
          studentObj = student;
        }
      });

      this.misisId = studentObj.parentMisisId;
      console.log(this.misisId);
    });
  }

  updateEmContactsList(list) {
    let contactList = [];
    for (let index = 0; index < list.length; index++) {
      let contactObj = list[index];
      let contactId = "emContactId" + index;
      let contactNum = (<HTMLInputElement>document.getElementById(contactId))
        .value;
      contactObj.cellNumber = contactNum;
      contactList.push(contactObj);
    }
    return contactList;
  }

  edit(index) {
    this.isEditing[index] = !this.isEditing[index];
    this.isReadOnly[index] = !this.isReadOnly[index];
    this.isDisabled[index] = !this.isDisabled[index];
    // if (index == 0) {
    //   this.isEditing0 = true;
    //   this.allowEdit(index);
    // }
    // if (index == 1) {
    //   this.isEditing1 = false;
    //   this.allowEdit(index);
    // }
  }

  editEm(index) {
    this.isEditingEm = index;
  }
  cancelEm(index) {
    this.isEditingEm = false;
  }
  onBlur(event){

  }
  saveEm() {

    this.isEditingEm = false;
    this.emContacts.emergencyContacts = this.updateEmContactsList(
      this.emContacts.emergencyContacts
    );
    this.emergencyContacts = this.emContacts;
    this.emContacts.signatureName = this.editorName;
    this.emContacts.signatureDate = this.getTime();

    this.studentService
      .updateStudentEmergencyContacts(
        this.studentId,
        this.parentId,
        this.emContacts
      )
      .subscribe(
        (response:any) => {
          if(response && response.errorResponse && response.errorResponse.errorMessage)
          {
            this.toastr.error(
              response.errorResponse.errorMessage
            );
            return;
          }
          if(response && response.status != 403 && response.status != 404)
          {
            this.toastr.success(`Successfully updated Emergency Contacts`);
            this.editorName = "";
            this.parentChange = false;
          }      
        },
        (error) => {
          this.toastr.error(
            `Could not update Emergency Contacts. Please try again.`
          );
        }
      );
  }

  allowEdit(index) {
    this.isReadOnly[index] = false;
    this.isEditing[index] = true;
    this.isDisabled[index] =false;
    // if (index == 0) {
    //   this.isReadOnly0 = false;
    //   this.isEditing0 = true;
    //   this.isDisabled0 = false;
    // }
    // if (index == 1) {
    //   this.isReadOnly1 = false;
    //   this.isEditing1 = true;
    //   this.isDisabled1 = false;
    // }
  }

  getTime() {
    return m().format("MM/DD/YYYY hh:mm A");
  }

  updatePhone(index) {
    this.emContacts.parents[index].cellNumber = (<HTMLInputElement>(
      document.getElementById(`cellParent${index}`)
    )).value;
    this.emContacts.parents[index].homeNumber = (<HTMLInputElement>(
      document.getElementById(`phoneParent${index}`)
    )).value;
    this.emContacts.parents[index].workNumber = (<HTMLInputElement>(
      document.getElementById(`workParent${index}`)
    )).value;
    this.emContacts.parents[index].emergency = [];
    this.emContacts.parents[index].attendance = [];
    this.emContacts.parents[index].generalInfo = [];
    if ((<HTMLInputElement>document.getElementById(`cEmer${index}`)).checked) {
      this.emContacts.parents[index].emergency.push("C");
    }
    if ((<HTMLInputElement>document.getElementById(`cAtt${index}`)).checked) {
      this.emContacts.parents[index].attendance.push("C");
    }
    if ((<HTMLInputElement>document.getElementById(`cGen${index}`)).checked) {
      this.emContacts.parents[index].generalInfo.push("C");
    }
    if ((<HTMLInputElement>document.getElementById(`pEmer${index}`)).checked) {
      this.emContacts.parents[index].emergency.push("H");
    }
    if ((<HTMLInputElement>document.getElementById(`pAtt${index}`)).checked) {
      this.emContacts.parents[index].attendance.push("H");
    }
    if ((<HTMLInputElement>document.getElementById(`pGen${index}`)).checked) {
      this.emContacts.parents[index].generalInfo.push("H");
    }
    if ((<HTMLInputElement>document.getElementById(`wEmer${index}`)).checked) {
      this.emContacts.parents[index].emergency.push("W");
    }
    if ((<HTMLInputElement>document.getElementById(`wAtt${index}`)).checked) {
      this.emContacts.parents[index].attendance.push("W");
    }
    if (
      (<HTMLInputElement>document.getElementById(`wGenInfo${index}`)).checked
    ) {
      this.emContacts.parents[index].generalInfo.push("W");
    }
  }

  cancel(index) {
    this.isEditing[index]=   !this.isEditing[index]
    this.isReadOnly[index] = !this.isReadOnly[index];
    this.isDisabled[index] = !this.isDisabled[index];
    this.ngOnInit();
  }
  isTelValid(field){
    var val = this.contactForm0.controls[field].value;
    if(val){
      if(val.length != 10){
        this.contactForm0.controls[field].setErrors({'incorrect': true});
      }
    }
  }
  save(index) {
    this.emContacts.signatureName = this.editorName;
    this.emContacts.signatureDate = this.getTime();
    this.isReadOnly[index] = true;
    this.isEditing[index] = false;
    this.isDisabled[index] = true;
    this.updatePhone(index);
    this.emergencyContacts = this.emContacts;
    this.studentService
      .updateStudentEmergencyContacts(
        this.studentId,
        this.parentId,
        this.emContacts
      )
      .subscribe(
        () => {
          this.toastr.success(`Successfully updated Emergency Contacts`);
        },
        (error) => {
          this.toastr.error(
            `Could not update Emergency Contacts. Please try again.`
          );
        }
      );

  }

  checkAdministrator(parentId) {
    if (this.role == "Administrator") {
      return false;
    } else {
      if (this.misisId == parentId) {
        return false;
      } else {
        return true;
      }
    }
  }

  checkEditor(parentId) {
    if (this.role == "Administrator") {
      return true;
    } else {
      if (this.misisId == parentId) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkNullData(emContacts) {
    // //check data in parents array
    if (emContacts.parents) {
      for (var p in emContacts.parents) {
        if (emContacts.parents[p] == null) {
          emContacts.parents[p] = "No data available";
        }
      }
    }
    // check data in emergency contacts array
    if (emContacts.emergencyContacts) {
      for (var p in emContacts.emergencyContacts) {
        if (emContacts.emergencyContacts[p] == null) {
          emContacts.emergencyContacts[p] = "No data available";
        }
      }
    }
    //check data in family members
    if (emContacts.familyMembers) {
      for (var p in emContacts.familyMembers) {
        if (emContacts.familyMembers[p] == null) {
          emContacts.familyMembers[p] = "No data available";
        }
      }
    }
    //check data in private insurance
    if (emContacts.privateInsurance) {
      for (var p in emContacts.privateInsurance) {
        if (emContacts.privateInsurance[p] == null) {
          emContacts.privateInsurance[p] = "No data available";
        }
      }
    }
    //check data in emContacts
    for (var p in emContacts) {
      if (emContacts[p] == null) {
        emContacts[p] = "No data available";
      }
    }
  }
}
