import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IFaqGroup } from 'src/app/shared/components/faq-section-widget/models/Faq';
import { HelpCenterService } from 'src/app/shared/services/help-center.service';
import { StudentsService } from 'src/app/shared/services/students.service';

import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';

import * as m from 'moment';
import { ProfileService } from 'src/app/shared/services/profile.service';
import swal from 'sweetalert'
import { NotificationServices } from 'src/app/shared/services/notification.service';
import { LoggingService } from "src/app/shared/services/logging.service";


declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}


@Component({
  selector: 'app-student-suspension',
  templateUrl: './student-suspension.component.html',
  styleUrls: ['./student-suspension.component.scss']
})
export class StudentSuspensionComponent implements OnInit {
  disciplines: any = [];
  last: any = null
  faq: IFaqGroup[] = null;
  studentId: string = null;
  lastUpdated: any;

  gotReport: boolean = false;
  isDownloading: boolean = false;
  successEng: boolean = false;
  successSp: boolean = false;
  failEng: boolean = false;
  failSp: boolean = false;
  englishReport: any = null;
  spanishReport: any = null;

  selectedReport: any;

  message: any = '';
  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    private helpService: HelpCenterService,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private loggingService: LoggingService,

  ) {}

  ngOnInit(): void {
    this.activeRoute.parent.params.subscribe((data) => {
      this.studentId = data.studentId;
      this.studentService
        .getStudentDiscipline(data.studentId)
        .subscribe((response) => {
          this.disciplines = response;
          // if(this.disciplines.length === 0){
          //   let noDisciplineData = [{
          //     suspension_date: undefined,
          //     suspension_days: 'No data available',
          //     suspension_type_descr: 'No data available',
          //   }];
          //   this.disciplines = noDisciplineData;
          // }
          this.last = this.disciplines[this.disciplines.length - 1]
        });
        this.lastUpdated = m();
    });
    this.helpService.getFaq("Suspension").subscribe((faq) => {
      this.faq = faq;
    });

    this.startTour();
  }


  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([{
      anchorId: 'start-tour',
      content: 'Here you can find any updates related to the student\'s discipline',
      title: 'Student Discpline',
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }
  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  getReport(participantId: any, idx) {
    //this.loggingService.insertLogging(this.studentId, 'Discipline - Get Report');
    this.selectedReport = idx;
    this.isDownloading = true;
    this.message = 'Please wait while we download your report';

    this.studentService.getStudentDisciplineLetter(this.studentId, participantId).subscribe(report => {
      this.englishReport = report.buffer;
      this.gotReport = true;
      this.successEng = true;
      this.message = 'Download was successful. Click to view.'
      if(this.englishReport.length < 1000) {
        this.successEng = false;
        this.failEng = true;
        this.message = 'Please check your internet connection and retry'
      }
    }, error => {this.failEng = true})
    // this.studentService.getStudentPdfReport(this.studentId, 'reportCard', 'spanish').subscribe((report)=> {
    //   this.spanishReport = report.buffer;
    // })
  }

  downloadReport(lang) {
    //this.loggingService.insertLogging(this.studentId, 'Discipline - Download Report');
    var ieEDGE = navigator.userAgent.match(/Edge/g);
    var ie = navigator.userAgent.match(/.NET/g); // IE 11+
    var oldIE = navigator.userAgent.match(/MSIE/g);

    if(lang == 'english'){
      var blob = this.blobPdfFromBase64String(this.englishReport);

      if (ie || oldIE || ieEDGE) {
        window.navigator.msSaveBlob(blob, `discipline_report_english.pdf`);
      } else {
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    }
    if(lang == 'spanish'){
      var blob = this.blobPdfFromBase64String(this.spanishReport);

      if (ie || oldIE || ieEDGE) {
        window.navigator.msSaveBlob(blob, `discipline_report_spanish.pdf`);
      } else {
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      }
    }


  }

  openPdf(participantId: any) {
    var ieEDGE = navigator.userAgent.match(/Edge/g);
    var ie = navigator.userAgent.match(/.NET/g); // IE 11+
    var oldIE = navigator.userAgent.match(/MSIE/g);

    this.studentService
      .getStudentDisciplineLetter(this.studentId, participantId)
      .subscribe((response) => {
        var blob = this.blobPdfFromBase64String(response.buffer);

        if (ie || oldIE || ieEDGE) {
          if (navigator.msSaveBlob)
            window.navigator.msSaveBlob(blob, `report_${participantId}.pdf`);
        } else {
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        }
      });
  }
  blobPdfFromBase64String = (base64String) => {
    const byteArray = Uint8Array.from(
      atob(base64String)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: "application/pdf" });
  };
}
