import { CdnService } from "./../../../services/cdn.service";
import { ProfileService } from "src/app/shared/services/profile.service";
import { ModalService } from "./../../services/modal.service";
import { StudentsService } from "src/app/shared/services/students.service";
import { ParentsService } from "src/app/shared/services/parents.service";
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import AsyncComponent from "../common/AsyncComponent";
import { ISchoolInfo } from "../student-list-widget/models/ISchoolInfo";
import { IStudentResume } from "../student-list-widget/models/IStudentResume";
import { ActivatedRoute, Router } from "@angular/router";
import { RoleServiceFactoryService } from "../../services/role-service-factory.service";
import * as _ from "lodash";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { TourService } from "ngx-ui-tour-md-menu";
import { MdMenuPlacement } from "ngx-ui-tour-md-menu";
import { environment } from "src/environments/environment";
import { OAuthService } from "angular-oauth2-oidc";
import { AppConfigService } from "src/app/services/app-config.service";
import { MatDialog } from "@angular/material/dialog";
import { RemoveStudentModalComponent } from "../remove-student-modal/remove-student-modal.component";
import { StudentMenuItem } from "./models/Student-MenuItem";
import { LoggingService } from "../../services/logging.service";


@Component({
  selector: "app-student-header-widget",
  templateUrl: "./student-header-widget.component.html",
  styleUrls: ["./student-header-widget.component.scss"],
})
export class StudentHeaderWidgetComponent implements OnInit, AsyncComponent {
  @Input() student: IStudentResume;
  @Input() schoolInfo: ISchoolInfo;
  // FIXME: not sure this one is necessary or not. do we have survey, cert etc in parent or student login mode?
  @Input() teacherLogin = false;
  @Input() role = "";
  @Input() hideMenu;

  // async component
  loaded = true;
  imgPath: string = null;
  self: StudentHeaderWidgetComponent = this;
  isHighSchool = false;
  studentName: string = null;
  firstName: string = null;
  middleName: string = null;
  lastName: string = null;
  schoolImg = null;
  schoolName = null;
  gradeDescription: String = "";
  userRole = "";
  schoologyLoading = false;

  menuItems: StudentMenuItem[];
  menuTopics: string[] = ['Favorite', 'All'];
  menuFilter = 'All';
  menuFavorites: string[] = [];
  menuFavoriteResources = [];

  menuRecentVisits: string[] = [];
  menuRecentItems = [];

  get isProd(): boolean {
    let env = environment.environment_name;
    if (env === 'Development') return false
    else if (env === 'Stage') return false
    else return true
  }

  get isStaff() {
    if (this.profileService.role == "Admin") return true;
    if (this.profileService.role == "Counselor") return true;
    if (this.profileService.role == "Teacher") return true;
    else return false;
  }

  get isCounselor() {
    return this.profileService.role == 'Counselor'
  }

  get isTeacher() {
    return this.profileService.role == 'Teacher'
  }

  get isStudent() {
    return this.profileService.role == 'Student'
  }

  get isParent() {
    return this.ps.role == "Parent";
  }

  get isAdmin() {
    return this.ps.role == "Administrator";
  }

  get showIgp() {
    return (
      parseInt(this.student.gradeCode) >= 7 &&
      parseInt(this.student.gradeCode) <= 12
    );
  }

  get isAboveGrade6() {
    return (
      parseInt(this.student.gradeCode) >= 6 &&
      parseInt(this.student.gradeCode) <= 12
    )
  }

  menuOpen: boolean = false;
  showRoute = false;

  token: any;
  studentId: any;
  email: any;
  action: any;
  lang = "en-us";
  ssoUrl: any;

  constructor(
    public rs: RoleServiceFactoryService,
    private ps: ProfileService,
    private router: Router,
    private parentServices: ParentsService,
    private studentService: StudentsService,
    private sanitize: DomSanitizer,
    private toastr: ToastrService,
    private modalServices: ModalService,
    private cdnService: CdnService,
    public tourService: TourService,
    private profileService: ProfileService,
    private oauthService: OAuthService,
    private appService: AppConfigService,
    private activatedRoute: ActivatedRoute,
    private appConfig: AppConfigService,
    private dialog: MatDialog,
    private loggingService: LoggingService

  ) { }

  ngOnInit() {

    this.loadRecentAndFavoriteMenuItems();
    this.setMenuItems();
    this.initMenuAnimation();

  }

  loadRecentAndFavoriteMenuItems() {

    this.parentServices.getFavoritesByParent().subscribe(
      (response) => {
        if (response?.length > 0) {

          response.forEach((element, index) => {
            this.menuFavorites.push(element.buttonId);
          });
        }
      },
      (error) => {
      }
    );

    //---disable recent---
    // this.parentServices.getRecentStudentMenuByParent().subscribe(
    //   (response) => {
    //     if (response) {
    //       this.menuRecentVisits = this.ConvertStringToArray(response.buttonId);
    //       this.setMenuRecentItems();

    //     }
    //   },
    //   (error) => {
    //   }
    // );
  }

  ngOnChanges(): void {
    console.log(this.isProd, 'config===')
    console.log(this.student);
    this.studentService
      .getStudentBusRoutes(this.student.studentId)
      .subscribe((response) => {
        if (response.routes != null) {
          this.showRoute = true;
        }
      });
    this.loaded = true;
    this.userRole = this.ps.role.toLowerCase();
    this.getGradeDesc(this.student);
    this.isHighSchool =
      parseInt(this.student.gradeCode) >= 9 &&
      parseInt(this.student.gradeCode) <= 12;
    // this.startTour();

    //get background img
    this.studentService
      .getStudentSchoolInfo(this.student.studentId)
      .subscribe((school) => {
        this.schoolName = school.schoolName;
        this.schoolImg = `linear-gradient(to top, rgba(47, 51, 201, 0.37), rgb(14, 44, 74)), url(${this.cdnService.schoolBackgrounds}${school.costCenterCode}/${school.costCenterCode}_1.jpg), url('/assets/img/school-image-bg.png')`;
        this.schoolImg = this.sanitize.bypassSecurityTrustStyle(this.schoolImg);
      });

    //get full name
    this.studentService
      .getStudentById(this.student.studentId)
      .subscribe((student) => {
        this.firstName = student.firstName;
        if (student.middleInitial != null) {
          this.middleName = student.middleInitial;
        } else {
          this.middleName = "";
        }
        this.lastName = student.lastName;
        this.studentName =
          this.firstName + " " + this.middleName + " " + this.lastName;
      });
  }

  initMenuAnimation() {
    this.menuOpen = true;
    setTimeout(() => {
      this.menuOpen = false;
    }, 2000);
  }

  navToStudentPage() {
    if (this.router.url.includes("timeline")) {
      this.menuOpen = true;
    } else {
      this.menuOpen = true;
      setTimeout(() => {
        this.menuOpen = false;
      }, 500);
    }
  }

  initFormValues() {
    this.studentId = this.student.studentId;
    this.email = this.profileService.profile.email;
    var accessToken = this.profileService.parseJwt(
      this.oauthService.getAccessToken()
    );
    this.token = accessToken["idcs-auth-token"];
  }



  uploadDocs() {
    this.initFormValues();
    this.ssoUrl = this.appService.configuration.environment.configuration.ppServices.externalLogin.enroll;

    this.action = "Uploads";
    setTimeout(() => {
      document.forms["stuEnr"].submit();
    }, 500);
  }
  requestDevice() {
    this.initFormValues();
    this.ssoUrl = this.appService.configuration.environment.configuration.ppServices.externalLogin.myapps;
    this.action = "DeviceRequest";
    setTimeout(() => {
      document.forms["stuEnr"].submit();
    }, 500);
  }
  goNaviance() {
    window.open("https://achieve.lausd.net/Page/15193", "_blank");
  }
  goDailyPass() {
    window.open("https://dailypass.lausd.net", "_blank");
  }
  getGradeDesc(student) {
    switch (student?.gradeCode) {
      case "PK":
        this.gradeDescription = "PreKindergartener @";
        break;
      case "K":
        this.gradeDescription = "Kindergartener @";
        break;
      case "1":
        this.gradeDescription = "1st Grader Student @";
        break;
      case "2":
        this.gradeDescription = "2nd Grader Student @";
        break;
      case "3":
        this.gradeDescription = "3rd Grader Student @";
        break;
      default:
        this.gradeDescription = `${student.gradeCode}th Grader Student @`;
    }
  }

  toggleMenu() {
    //this.loggingService.insertLogging(this.student, 'Student Menu');
    this.menuOpen = !this.menuOpen;
  }

  // startTour() {
  //   let above: MdMenuPlacement = { yPosition: 'above' }
  //   let below: MdMenuPlacement = { yPosition: 'below' }
  //   let left: MdMenuPlacement = { xPosition: 'before' }
  //   let right: MdMenuPlacement = { xPosition: 'after' }

  //   this.tourService.initialize([{
  //     anchorId: 'start-tour',
  //     content: 'Here you will find access to all your information',
  //     title: 'Welcome to your Student Profile!',
  //   },
  //   ]);
  //   this.tourService.start();
  // }


  studentImage(student: IStudentResume) {
    return `https://uifaces.co/our-content/donated/Te-0H20q.png`;
  }
  goSchoology() {
    this.schoologyLoading = true;
    this.parentServices
      .getSchoologyRemoteLogin("web", this.student.districtId)
      .subscribe((response) => {
        this.schoologyLoading = false;
        if (response.errorResponse.errorCode) {
          this.toastr.error(`${response.errorResponse.errorMessage} - Please contact the Help Desk at (213)241-5200`, 'LAUSD', {
            extendedTimeOut: 4000
          });
          return
        }
        if (response.remoteLoginURL) {
          window.open(
            response.remoteLoginURL + "/parent/grades_attendance/grades"
          );
        }
      }, error => {
        this.schoologyLoading = false;
      });
  }
  goMenu() {
    window.open("https://lausd.yumyummi.com/webapp");
  }

  getIgpReport() {
    var ieEDGE = navigator.userAgent.match(/Edge/g);
    var ie = navigator.userAgent.match(/.NET/g); // IE 11+
    var oldIE = navigator.userAgent.match(/MSIE/g);

    this.studentService
      .getStudentPdfReport(this.student.studentId, "igp", "english")
      .subscribe((response) => {
        var blob = this.blobPdfFromBase64String(response.buffer);

        if (ie || oldIE || ieEDGE) {
          window.navigator.msSaveBlob(blob, `report_igp.pdf`);
        } else {
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        }
      });
  }

  getGraduationReport() {
    this.studentService
      .getStudentGraduationReport(this.student.studentId)
      .subscribe((response) => {
        var blob = this.blobPdfFromBase64String(response.buffer);
        var ieEDGE = navigator.userAgent.match(/Edge/g);
        var ie = navigator.userAgent.match(/.NET/g); // IE 11+
        var oldIE = navigator.userAgent.match(/MSIE/g);

        if (ie || oldIE || ieEDGE) {
          window.navigator.msSaveBlob(blob, `report.pdf`);
        } else {
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        }
        //window.open(response.url);
      });
  }
  blobPdfFromBase64String = (base64String) => {
    const byteArray = Uint8Array.from(
      atob(base64String)
        .split("")
        .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: "application/pdf" });
  };

  removeStudent() {
    //this.loggingService.insertLogging(this.student, 'Remove Student Modal - open');

    this.dialog
      .open(RemoveStudentModalComponent, {
        data: {
          student: this.student,
        },
        minWidth: '322px'
      })
      .afterClosed()
      .subscribe((res) => {
        //this.loggingService.insertLogging(this.student, 'Remove Student Modal - close');

        if (res) {
          this.parentServices.removeStudent(this.student.studentId).subscribe(() => {
            this.parentServices.getStudentsByParentNoCache().subscribe(() => {
              //this.loggingService.insertLogging(this.student, ` Remove Student - ${this.firstName} ${this.lastName}`);

              this.router.navigateByUrl("/parent/students");

              this.toastr.success(
                `${this.firstName} ${this.lastName} successfully removed. However, it could take a few minutes before the student is fully removed from this account.`
              );
            });
          }, error => {
            this.toastr.error(
              `Could not delete ${this.firstName} ${this.lastName}. Try again.`
            );
          });
        }
      });
  }


  cancelRemove() {
    this.modalServices.hide();
  }

  confirmRemove() {
    this.modalServices.hide();
    this.parentServices.removeStudent(this.student.studentId).subscribe(() => {
      this.router.navigateByUrl("/parent/students");
      this.toastr.warning(
        `Successfully deleted ${this.firstName} ${this.lastName}`
      );
    });
  }

  gotoSurvey() {
    //this.loggingService.insertLogging(this.student.studentId, 'Survey');
    this.router.navigate([
      "/teacher/classes",
      this.student.studentId,
      "survey",
    ]);
  }

  gotoCertificates() {
    //this.loggingService.insertLogging(this.student.studentId, 'Certificate');
    this.router.navigate([
      "/teacher/classes",
      this.student.studentId,
      "certificate",
    ]);
  }

  gotoFiles() {
    //this.loggingService.insertLogging(this.student.studentId, 'Files');
    this.router.navigate(["/teacher/classes", this.student.studentId, "files"]);
  }

  gotoExpectations() {
    //this.loggingService.insertLogging(this.student.studentId, 'Expectations');
    this.router.navigate([
      "/teacher/classes",
      this.student.studentId,
      "expectations",
    ]);
  }


  setMenuRecentItems() {

    var ids = this.menuRecentVisits;
    var allItems = this.getAllMenuItems();

    this.menuRecentItems = [];

    ids.forEach((value, index) => {

      var item = allItems.find(x => x.buttonId === value);
      if (item != undefined)
        this.menuRecentItems.push(item);

    });

  }

  setMenuItems() {

    this.menuItems = this.getAllMenuItems();
  }

  getAllMenuItems() {

    //---NOTE: buttonId must match buttonID in MobileApp
    return [
      {
        buttonId: "2",
        title: "Timeline",
        routerLink: "timeline",
        imagePath: "/assets/img/icons/studentProfile/Timeline.png",
        isVisible: true
      },
      {
        buttonId: "3",
        title: "Assignments",
        routerLink: "assignments",
        imagePath: "/assets/img/icons/studentProfile/assignment.png",
        isVisible: true
      },
      {
        buttonId: "4",
        title: "Attendance",
        routerLink: "attendance",
        imagePath: "/assets/img/icons/studentProfile/attendance.png",
        isVisible: true
      },
      {
        buttonId: "5",
        title: "School Bus",
        routerLink: "transportation",
        imagePath: "/assets/img/icons/studentProfile/transportation.png",
        isVisible: true
      },
      {
        buttonId: "28",
        title: "Certificates",
        routerLink: "certificates",
        imagePath: "/assets/img/icons/studentProfile/Certificate.png",
        isVisible: (!this.isProd && (this.isTeacher || this.isCounselor))
      },
      {
        buttonId: "7",
        title: "Checklists",
        routerLink: "checklists",
        imagePath: "/assets/img/icons/studentProfile/checklist.svg",
        isVisible: (!this.isProd && (this.isTeacher || this.isCounselor || this.isStudent))
      },
      {
        buttonId: "6",
        title: "College & Career",
        routerLink: "ccrg",
        imagePath: "/assets/img/icons/studentProfile/ccrg.png",
        isVisible: (this.isAboveGrade6)
      },
      {
        buttonId: "8",
        title: "Discipline",
        routerLink: "suspension",
        imagePath: "/assets/img/icons/studentProfile/suspension.png",
        isVisible: (this.isParent || this.isAdmin)
      },
      {
        buttonId: "9",
        title: "Emergency Contact",
        routerLink: "emergency",
        imagePath: "/assets/img/icons/studentProfile/emergency.png",
        isVisible: (!this.isStudent)
      },

      {
        buttonId: "10",
        title: "English Learner Progress",
        routerLink: "elp",
        imagePath: "/assets/img/icons/studentProfile/esl.png",
        isVisible: true
      },
      {
        buttonId: "11",
        title: "Reinforcements",
        routerLink: "expectations",
        imagePath: "/assets/img/icons/studentProfile/Expectations.png",
        isVisible: (!this.isProd && (this.isTeacher || this.isCounselor))
      },
      {
        buttonId: "12",
        title: "Files",
        routerLink: "files",
        imagePath: "/assets/img/icons/studentProfile/Files.png",
        isVisible: (!this.isProd && (this.isTeacher || this.isCounselor || this.isStudent))
      },
      {
        buttonId: "16",
        title: "Meals & Nutrition",
        routerLink: "",
        imagePath: "/assets/img/icons/studentProfile/menu-icon.png",
        isVisible: true
      },
      {
        buttonId: "13",
        title: "GATE Programs",
        routerLink: "gate",
        imagePath: "/assets/img/icons/studentProfile/gate.png",
        isVisible: true
      },
      {
        buttonId: "14",
        title: "Grades",
        routerLink: "grades",
        imagePath: "/assets/img/icons/studentProfile/grades.png",
        isVisible: true
      },
      {
        buttonId: "18",
        title: "Health and Wellness",
        routerLink: "healthandwellness",
        imagePath: "/assets/img/icons/studentProfile/immunization.png",
        isVisible: true
      },
      {
        buttonId: "17",
        title: "Report Card",
        routerLink: "reportcard",
        imagePath: "/assets/img/icons/studentProfile/reportcard.png",
        isVisible: true
      },
      {
        buttonId: "22",
        title: "Request a Device",
        routerLink: "",
        imagePath: "/assets/img/icons/studentProfile/request-device.png",
        // isVisible: (this.isParent)
        isVisible: false
      },
      {
        buttonId: "19",
        title: "Schoology",
        routerLink: "",
        imagePath: "/assets/img/icons/studentProfile/schoology-logo.png",
        isVisible: (!this.isAdmin)
      },
      {
        buttonId: "21",
        title: "Special Education",
        routerLink: "specialEd",
        imagePath: "/assets/img/Portal_Sped_Logo.png",
        isVisible: (this.isParent || this.isAdmin)
      },
      {
        buttonId: "24",
        title: "Surveys",
        routerLink: "surveys",
        imagePath: "/assets/img/icons/studentProfile/Surveys.png",
        isVisible: (!this.isProd && (this.isTeacher || this.isCounselor || this.isStudent))
      },
      {
        buttonId: "25",
        title: "Test Scores",
        routerLink: "testing",
        imagePath: "/assets/img/icons/studentProfile/testing.png",
        isVisible: true
      },
      {
        buttonId: "23",
        title: "Upload Documents",
        routerLink: "",
        imagePath: "/assets/img/icons/studentProfile/upload-docs.png",
        isVisible: (this.isParent)
      },
      {
        buttonId: "15",
        title: "Naviance",
        routerLink: "",
        imagePath: "/assets/img/icons/studentProfile/naviance.png",
        isVisible: (!this.isProd && !this.isParent)
      },
      {
        buttonId: "26",
        title: "Daily Pass",
        routerLink: "",
        imagePath: "/assets/img/icons/studentProfile/dailypass.png",
        isVisible: (!this.isParent && (!this.isStaff && !this.isProd))
      },
      {
        buttonId: "27",
        title: "Online Library",
        routerLink: "online-library",
        imagePath: "/assets/img/icons/studentProfile/onlinelibrary.png",
        isVisible: (!this.isStaff)
      },
      {
        buttonId: "28",
        title: "QR Codes",
        routerLink: "buspassqrcodes",
        imagePath: "/assets/img/icons/studentProfile/qrcode.png",
        isVisible: (this.isParent)
        // isVisible:  (this.isParent || this.isStudent)
      },
      {
        buttonId: "29",
        title: "Tutoring Services",
        routerLink: "tutoring-services",
        imagePath: "/assets/img/icons/studentProfile/tutoringservices.png",
        isVisible:  (this.isParent || this.isStudent)
      }
    ]
  }

  menuItemClick(item: StudentMenuItem) {
    let menuClicked = item.title;

    switch (menuClicked) {
      case "School Bus":
        menuClicked = "Transportation Home";
        break;
      case "GATE Programs":
        menuClicked = "GATE";
        break;
      case "Report Card":
        menuClicked = "Report Card Home";
        break;
      case "Special Education":
        menuClicked = "SPED - Home";
        break;
      case "Test Scores":
        menuClicked = "Test Scores Home";
        break;
      case "College & Career":
        menuClicked = "College & Career Home";
        break;
      case "QR Codes":
        menuClicked = "QR Code - Bus Pass";
        break;
      case "Meals & Nutrition":
        menuClicked = "Meals";
        break;
      case "Health and Wellness":
        menuClicked = "Health and Wellness Home";
        break;
      case "Upload Documents":
        menuClicked = "Upload Docs";
        break;
      default:
        break;
    }

    this.loggingService.insertLogging(this.student, menuClicked);

    if (item.title == "Meals & Nutrition") {
      this.goMenu();
    }
    else if (item.title == "Request a Device") {
      this.requestDevice();
    }
    else if (item.title == "Schoology") {
      this.goSchoology();
    }
    else if (item.title == "Upload Documents") {
      this.uploadDocs();
    }
    else if (item.title == "Naviance") {
      this.goNaviance();
    }
    else if (item.title == "Daily Pass") {
      this.goDailyPass();
    }
    else {
      if (this.isTeacher) {
        this.router.navigate([
          "/teacher/classes",
          this.student.studentId,
          item.routerLink,
        ]);
      }
      else {
        const currentUrl = this.router.url;
        const currentPaths = currentUrl.split('/');
        currentPaths.pop(); // remove the last path
        currentPaths.push(item.routerLink); // add the new path
        const newUrl = currentPaths.join('/');
        this.router.navigate([newUrl]);
      }
    }

    setTimeout(() => {
      this.menuOpen = false;
    }, 200);

    //--disable add recent
    //this.addRecent(item.buttonId);

  }


  toggleFavorite(buttonId, title, e: Event) {

    e.stopPropagation();
    const index = this.menuFavorites.indexOf(buttonId);
    if (index < 0) {
      this.menuFavorites.push(buttonId);
      this.parentServices.addfavoriteStudentMenuByParent(buttonId).subscribe(
        (response) => { },
        (error) => {
          //this.toastr.error('Error adding to favorites');
        }
      );
      //this.toastr.success('Successfully added to favorites');
    } else {
      this.menuFavorites.splice(index, 1);
      this.parentServices.removeFavoriteStudentMenu(buttonId).subscribe(
        (response) => { },
        (error) => {
          //this.toastr.error('Error removing menufavorites');
        }
      );
      //this.toastr.success('Successfully removed from favorites');
    }
    localStorage.setItem('favoriteMenuResources', JSON.stringify(this.menuFavorites));
    //this.loggingService.insertLogging(this.studentId, `Toggle Favorite - ${title}`);
  }



  addRecent(buttonId) {

    const index = this.menuRecentVisits.indexOf(buttonId);
    // if exists and its position is 0 then do nothing
    if (index == 0) { }
    else {
      //if existing then delete the existing one
      if (index > 0) {
        this.menuRecentVisits.splice(index, 1);
      }
      //if length is greater or equal to 5
      if (this.menuRecentVisits.length >= 5) { //remove last element
        this.menuRecentVisits.pop();
      }
      //add to the position 0
      const existrecentvisits = this.menuRecentVisits
      this.menuRecentVisits = [];
      this.menuRecentVisits.push(buttonId);

      if (existrecentvisits.length == 0) {

        let recents = this.convertArrayToString(this.menuRecentVisits);
        this.parentServices.updateRecentStudentMenuByParent(recents).subscribe(
          (response) => {

          },
          (error) => {

          }
        );
      }
      else {
        existrecentvisits.forEach((element, index) => {
          this.menuRecentVisits.push(element);
          var isLastElement = index == existrecentvisits.length - 1;
          if (isLastElement && this.menuRecentVisits.length >= existrecentvisits.length) {

            let recents = this.convertArrayToString(this.menuRecentVisits);
            this.parentServices.updateRecentStudentMenuByParent(recents).subscribe(
              (response) => {

              },
              (error) => {

              }
            );
          }
        });
      }

      this.setMenuRecentItems();

    }

    localStorage.setItem('recentMenuResources', JSON.stringify(this.menuRecentVisits));

  }

  showMenuItem(item: StudentMenuItem) {
    if (item.isVisible == false)
      return false;
    else if (this.menuFilter === 'Favorite' && this.menuFavorites.indexOf(item.buttonId) >= 0)
      return true;
    else if (this.menuFilter === 'All') // && this.menuRecentVisits.indexOf(item.buttonId) < 0
      return true;
    else
      return false;
  }

  changeMenuFilter(value, e: Event) {
    e.stopPropagation();
    //this.loggingService.insertLogging(this.student, 'Menu Filter');
    this.menuFilter = value;

  }

  convertArrayToString(menus) {
    return menus.join(",")
  }
  ConvertStringToArray(menus) {
    return menus.split(',');
  }
}
