import { ParentsService } from './../../../../shared/services/parents.service';
import { ActivatedRoute, Router } from "@angular/router";
import { StudentsService } from "./../../../../shared/services/students.service";
import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";
import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { ProfileService } from 'src/app/shared/services/profile.service';
import swal from 'sweetalert'
import { NotificationServices } from 'src/app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { LoggingService } from "src/app/shared/services/logging.service";


@Component({
  selector: "app-student-assignments",
  templateUrl: "./student-assignments.component.html",
  styleUrls: ["./student-assignments.component.scss"],
})
export class StudentAssignmentsComponent implements OnInit {
  assignments: any = null;
  schoologyUrl: any;
  allAssignments: any = null;
  dueAssignments: any = null;
  lastUpdated: any;
  dueNext: boolean = true;
  byCourse: boolean = false;
  all: boolean = false;
  student = null;
  isLoading = false;
  schoologyLoading = false;
  selectedLink = null;

  constructor(
    private studentsService: StudentsService,
    private activeRoute: ActivatedRoute,
    private parentServices: ParentsService,
    public tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private toastr: ToastrService,
    private router: Router,
    private loggingService: LoggingService,
  ) {}

  ngOnInit(): void {
    this.activeRoute.parent.params.subscribe((data) => {
      let studentId = data.studentId;
      this.isLoading = true;
      this.studentsService
        .getStudentAssignmentsByStudent(studentId)
        .subscribe((response) => {

          if(response == null){
            this.isLoading = false;
            this.dueAssignments = []
            this.assignments = true;
            this.dueNext = true;
            return;
          }

          this.isLoading = false;
          this.student = this.studentsService.currentStudent();
          //this.loggingService.insertLogging(this.student, 'Student Assignments');
          this.assignments = response;
          this.assignments.section.forEach(assign => {
            if (assign.assignments.assignment.length > 0) {
              assign.assignments.assignment = assign.assignments.assignment.sort((a, b) => {
                return this.getTime(b.due) - this.getTime(a.due);
              });
            }
          });
          this.getAllAssignments(this.assignments.section);
          this.getDueAssignments(this.allAssignments);
          this.lastUpdated = moment();

          this.handleUrl();
        }, error => {
          this.handleUrl();
          this.isLoading = false;
          //this.toastr.error(`Can't load Assignments`);
        });
    });
    let student = this.studentsService.currentStudent();
    // this.parentServices.getSchoologyRemoteLogin('web',student.schoologyID ||  student.schoologyId).subscribe((response)=>{
    //   this.schoologyUrl = response.remoteLoginURL;
    // })




    this.startTour();
  }

  handleUrl() {
    const url = this.router.url.split('/');
    const showAll = url[5] === 'all';
    if(showAll) this.switchTab('all');
  }

  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
    {
      anchorId: 'start-tour',
      content: 'All your assignments will be posted here',
      title: 'Assignments',
    },
    {
      anchorId: 'tab1',
      content: 'You can view the next 7 days of assignments...',
      title: 'Assignments',
    },
    {
      anchorId: 'tab2',
      content: 'View by Course...',
      title: 'Assignments',
    },
    {
      anchorId: 'tab3',
      content: 'Or view all assignments, including past ones',
      title: 'Assignments',
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }

  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  goSchoology() {
    this.schoologyLoading = true;
    this.parentServices
      .getSchoologyRemoteLogin("web", this.student.districtId)
      .subscribe((response) => {
        this.schoologyLoading = false;
        //this.loggingService.insertLogging(this.student, `Assignment - click ${this.selectedLink.title}`);
        this.selectedLink = null;
        if(response.errorResponse.errorCode){
          this.toastr.error(`${response.errorResponse.errorMessage} - Please contact the Help Desk at (213)241-5200`,'LAUSD', {
            extendedTimeOut:4000
          });
          return
        }
        if(response.remoteLoginURL){
          window.open(
            response.remoteLoginURL + "/parent/grades_attendance/grades"
          );
        }
      }, error => {
        this.schoologyLoading = false;
        this.selectedLink = null;
      });
  }

  // goSchoology(taskId) {
  //   this.parentServices.getSchoologyRemoteLoginAssigment('web', this.student.districtId, taskId).subscribe((response) => {
  //     window.open(response.remoteLoginURL);
  //   });
  // }
  // goSchoologyCourse(courseId) {
  //   this.parentServices.getSchoologyRemoteLoginCourse('web', this.student.districtId, courseId).subscribe((response) => {
  //     window.open(response.remoteLoginURL);
  //   });
  // }

  switchTab(tab: string) {
    //this.loggingService.insertLogging(this.student, `Assignments - open ${tab}`);
    this.dueNext = false;
    this.byCourse = false;
    this.all = false
    switch (tab) {
      case 'dueNext':
        this.dueNext = true;
        break;
      case 'byCourse':
        this.byCourse = true;
        break;
      case 'all':
        this.all = true;
        break;
    }
  }
  dueTasks(section: any) {
    return section.filter((a) => {
      var tasks = a.assignments.assignment.filter((task) => task.due != "");
      a.assignments.assignment = tasks;
      return tasks.length > 0;
    });
  }
  getAllAssignments(tasks){
    var taskList: any = [];
    tasks.forEach(task => {
      taskList.push(task.assignments.assignment)
    });
    taskList = _.remove(taskList, function (task: any){
      return task.length > 0;
    });
    var filteredTasks:any = _.flattenDeep(taskList);
    //check for undefined grade info
    filteredTasks.forEach(task => {
      if(task.gradeInfo == null)
      task.gradeInfo = undefined
      else if(task.gradeInfo == "")
      task.gradeInfo = undefined
      else if(task.gradeInfo.length == 0)
      task.gradeInfo = undefined
      else if(task.gradeInfo.grade == null)
      task.gradeInfo.grade = ''
      else return
    });
    filteredTasks = filteredTasks.sort((a, b) => {
      return this.getTime(b.due) - this.getTime(a.due);
    });
    this.allAssignments = filteredTasks;
  }
  getDueAssignments(tasks){
    var yesterday = moment().startOf('day').subtract(1, 'd');
    var next7Days = moment().startOf('day').add(1,'week')
    var dueTasks: any = [];
    tasks.forEach(task => {
      if(moment(task.due).isBetween(yesterday, next7Days) == true){
        dueTasks.push(task);
      }
    });
    this.dueAssignments = dueTasks;
  }

  private getTime(date?: Date) {
    return date ? new Date(date).getTime() : 0;
  }
}
