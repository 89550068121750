import { CourseList, TermList } from '../../../services/student.service.models';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { IStudentAttendances } from "src/app/shared/services/student.service.models";
import { StudentsService } from "src/app/shared/services/students.service";
import * as moment from 'moment';

import { TourService } from 'ngx-ui-tour-md-menu';
import { MdMenuPlacement } from 'ngx-ui-tour-md-menu';
import { ProfileService } from 'src/app/shared/services/profile.service';
import swal from 'sweetalert'
import { NotificationServices } from 'src/app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from "rxjs/operators";
import { throwError } from 'rxjs';
import { environment } from "src/environments/environment";
import { LoggingService } from "src/app/shared/services/logging.service";


@Component({
  selector: "app-student-attendance",
  templateUrl: "./student-attendance.component.html",
  styleUrls: ["./student-attendance.component.scss"],
})
export class StudentAttendanceComponent implements OnInit {
  env = environment;
  attendance: any = [];
  overallAttendanceAvailable = false;
  overallAttendance = null;
  courseList: CourseList[] = [];
  lastUpdated: any;

  summary: boolean = true;
  byDate: boolean = false;
  byCourse: boolean = false;
  selectedTerm: any;
  selectedTermIndex: number;
  dateList = [];
  byCourseList = [];
  student: string;

  isLoading = false;

  attendanceRes: any

  constructor(
    private activeRoute: ActivatedRoute,
    private studentService: StudentsService,
    public readonly tourService: TourService,
    private profileService: ProfileService,
    private notificationServices: NotificationServices,
    private toastr: ToastrService,
    private loggingService: LoggingService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.selectedTermIndex = this.selectedTermIndex || 0;

    this.activeRoute.parent.params.subscribe((data) => {

      this.attendanceRes = this.studentService.getStudentAttendace(data.studentId);
      this.student = data.studentId;
      this.attendanceRes.pipe(
        catchError(err => {
          this.isLoading = false;
          console.log('Handling error locally and rethrowing it...', err);
          return throwError(err);
      })
      ).subscribe(attendance => {
        this.isLoading = false;
        this.attendance = attendance;
        if(attendance?.termList?.length > 0){
          this.courseList = attendance.termList[0].courseList;
          this.selectedTerm = this.attendance.termList[0];
          this.dateList = this.getDateList(this.attendance.termList);
          this.byCourseList = this.getCourseList(this.attendance.termList);
        }
        this.lastUpdated = moment();


        this.startTour();
      });

      //---overall attendance---
      this.loadIAPAttendance(data.studentId);




      //  this.studentService
      //   .getStudentAttendace(data.studentId)
      //   .subscribe((attendance) => {
      //     this.isLoading = false;
      //     this.attendance = attendance;
      //     if(attendance?.termList?.length > 0){
      //       this.courseList = attendance.termList[0].courseList;
      //       this.selectedTerm = this.attendance.termList[0];
      //       this.dateList = this.getDateList(this.attendance.termList);
      //       this.byCourseList = this.getCourseList(this.attendance.termList);
      //     }
      //     this.lastUpdated = moment();


      //     this.startTour();
      //   }, error => {
      //     this.isLoading = false;
      //     //this.toastr.error(`Can't load Attendance`);
      //   });
    });
  }


  startTour() {
    let above: MdMenuPlacement = { yPosition: 'above' }
    let below: MdMenuPlacement = { yPosition: 'below' }
    let left: MdMenuPlacement = { xPosition: 'before' }
    let right: MdMenuPlacement = { xPosition: 'after' }


    this.tourService.initialize([
    {
      anchorId: 'start-tour',
      content: 'All attendance updates will be accessible here',
      title: 'Attendance',
    },
    {
      anchorId: 'tab1',
      content: 'You can view an overall summary of them. Click the row to expand further details...',
      title: 'Attendance',
    },
    {
      anchorId: 'tab2',
      content: 'View by Date...',
      title: 'Attendance',
    },
    {
      anchorId: 'tab3',
      content: 'Or view by course',
      title: 'Attendance',
    },
    ]);
    if(this.profileService.profile.hasTour){
      this.tourService.start();
    }
  }

  openTourConfirmation() {
    swal({
      title: `End Tour?`,
      icon: 'warning',
      text: "You can always turn it back on in the Preferences sidebar",
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, turn off tour',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then((res) => {
      if (res) { // OK clicked---
        let userProfile = this.profileService.profile;
        userProfile.hasTour = false;
        this.profileService.saveProfile(userProfile).subscribe(() => {
          this.profileService.getProfile().subscribe(() => {
            this.notificationServices.showSuccessToast(
              "Your profile has been updated"
            );
            window.location.reload();
          });
        });
      }
    });
  }

  getTerm(i) : TermList{
    return this.attendance.termList[i]
  }

  switchTab(tab: string) {
    this.summary = false;
    this.byDate = false;
    this.byCourse = false
    switch (tab) {
      case 'summary':
        this.summary = true;
        break;
      case 'byDate':
        this.byDate = true;
        break;
      case 'byCourse':
        this.byCourse = true;
        break;
    }
  }

  getMarks(course){
    let markList = [];
    if(course.daysAbsent.length > 0){
      _.each(course.daysAbsent, (day)=>{
        day.type = 'Absent';
        markList.push(day)
      })
    }
    if(course.daysTardy.length > 0){
      _.each(course.daysTardy, (day)=>{
        day.type = 'Tardy';
        markList.push(day)
      })
    }
    markList = _.orderBy(markList, ['reasonDate'], ['desc']);
    return markList
  }

  getDateList(termList){
    let dateList = [];
    if(termList.length > 0){
      _.each(termList, (term) => {
        if(term.courseList.length > 0){
          _.each(term.courseList, (course) => {
            if(course.daysAbsent.length > 0){
              _.each(course.daysAbsent, (day)=>{
                day.type = 'Absent';
                day.course = course.courseName;
                dateList.push(day);
              })
            }
            if(course.daysTardy.length > 0){
              _.each(course.daysTardy, (day)=>{
                day.type = 'Tardy';
                day.course = course.courseName;
                dateList.push(day);
              })
            }
          })
        }
      })
    }
    dateList = _.orderBy(dateList, ['reasonDate'], ['asc']);
    return dateList
  }

  getCourseList(termList){
    let courseList = [];
    if(termList.length > 0){
      _.each(termList, (term) => {
        if(term.courseList.length > 0) {
          _.each(term.courseList, (course) => {
            courseList.push(course)
          })
        }
      })
    }
    courseList = this.orderCourseList(courseList);
    return courseList
  }

  changeTerm(term){
    this.selectedTermIndex = term;
    this.selectedTerm = this.attendance.termList[term];
  }


  orderCourseList(courseList){
    courseList = _.orderBy(courseList, ['period'], 'asc')
    return courseList
  }

  loadIAPAttendance(studentId) {
      
    if (this.env.enableIAP) {
      this.studentService.getStudentIAPAttendace(studentId).subscribe(
        (attendance) => {

          if (attendance.error == "") {

            this.overallAttendance = attendance;
            this.overallAttendance.color = "overall-attendance " + this.overallAttendance.color;
            this.overallAttendanceAvailable = true;
            
          }
        }        
      );
    }      
  }

  openAttendanceLink(url) {
    //this.loggingService.insertLogging(this.student, 'Student Attendance Link');
    if (url != "")
      window.open(url, "_blank");
  }

  loadingIndicator = true;
  reorderable = true;


  columns = [
    { prop: "courseStartDate", name:"Date" },
    { prop: "period", name:"Period" },
    { prop: "courseName", name:"Course Name" },
    { prop: "absence", name:"Absence" },
    { prop: "tardy", name:"Tardy" },
  ];

  //ColumnMode = ColumnMode;
}
